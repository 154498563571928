import mmcAjax from "@/common/js/mmcAjax"

export default {
    getList(params) {
        let url = `${window.APIBasePath}mmc/project/show/list/${params.page}?time=${params.time}`

        return mmcAjax({
            method: 'get',
            url,
        })
    }
}
