<template>
    <footer class="mainNav">
        <a v-for="(item, index) in data" :key="index" :href="item.link" class="nav-item">
            <i class="nav-icon" :class="{active: item.isActive}"></i>
            <span class="nav-title" :class="{active: item.isActive}">{{ item.title }}</span>
        </a>
    </footer>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        activeIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            data: [{
                    isActive: false,
                    title: '爱心首页',
                    link: '/fund/home'
                },
                {
                    isActive: false,
                    title: '发起',
                    link: '/fund/publish/guide'
                },
                {
                    isActive: false,
                    title: '个人中心',
                    link: '/fund/my'
                }
            ],
            showGuide: false,
        };
    },
    created() {
        this.data[this.activeIndex].isActive = true;
    },
    methods: {}
};
</script>

<style lang="less" scoped>
    .mainNav {
        box-shadow: 0 -7px 12px 4px rgba(204, 204, 204, 0.15);
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: 50px;
        background: #fff!important;
        display: flex;
        &::before {
            display: none;
        }
    }

    .nav-item {
        width: 20%;
        flex: 1 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nav-title {
            color: #0A0A0A;
            display: block;
            font-size: 11px;
            &.active {
                color: #34B4FF;
            }
        }
        .nav-icon {
            width: 24px;
            height: 24px;
            display: block;
            margin-bottom: 5px;
        }
        &:nth-child(1) {
            .nav-icon {
                background: url(./img/home.png) no-repeat center center;
                background-size: contain;
                &.active {
                    background: url(./img/home-active.png) no-repeat center center;
                    background-size: contain;
                }
            }
        }
        &:nth-child(2) {
            position: relative;
            .nav-icon {
                width: 59px!important;
                height: 48px!important;
                margin-top: -22px!important;
                background: url(./img/dd.png) no-repeat center center;
                background-size: contain;
                margin-bottom: 3px;
            }
        }
        &:nth-child(3) {
            .nav-icon {
                background: url(./img/my.png) no-repeat center center;
                background-size: contain;
                &.active {
                    background: url(./img/my-active.png) no-repeat center center;
                    background-size: contain;
                }
            }
        }
    }

    // 启用硬件加速 GPU 使动画更流畅
    @-webkit-keyframes topDown {
        0% {
            -webkit-transform: translate3d(0, 10%, 0);
                    transform: translate3d(0, 10%, 0);
        }
        100% {
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
        }
    }
    @keyframes topDown {
        0% {
            -webkit-transform: translate3d(0, 10%, 0);
                    transform: translate3d(0, 10%, 0);
        }
        100% {
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
        }
    }
</style>
